<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                  @change="getOperationInfo"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Login_Name')" prop="account">
                <el-input
                  v-model="formInline.account"
                  maxlength="20"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.full_name')" prop="pdaManagerName">
                <!-- <el-input
              v-model="formInline.pdaManagerName"
              maxlength="20"
              placeholder="请输入姓名"
            ></el-input> -->
                <el-select
                  v-model="formInline.pdaManagerName"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in pdaManagerNameOptions"
                    :key="item.pdaManagerId"
                    :label="item.pdaManagerName"
                    :value="item.pdaManagerName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.On_duty_status')" prop="">
                <el-select
                  v-model="formInline.pdaManagerState"
                  placeholder="请选择"
                  filterable
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="在职" value="1"></el-option>
                  <el-option label="离职" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="时段" prop="dateType">
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defaultRange="defaultRangeValue"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                @click="searchData"
                >查询
              </el-button>
              <el-button
                type="info"
                icon="el-icon-delete"
                @click="clear"
                :loading="loading"
                >清空
              </el-button>
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.button.batchExport || true"
          ></div>
          <div
            class="col_box h44"
            v-if="$route.meta.authority.button.batchExport || true"
          >
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button
                type="info"
                v-if="$route.meta.authority.button.batchExport || true"
                icon="el-icon-upload2"
                @click="
                  pageNum = 1;
                  exportExcelReport();
                "
                :loading="loading"
                >导出
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <!-- 筛选按钮 -->
        <div class="switch">
          <screen
            :colData="colData"
            :checkBoxGroup="tableCols"
            :checkedColumns="checkedColumns"
            @setReportFieldSave="setReportFieldSave"
            @setColData="setColData"
          ></screen>
        </div>
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols1"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="money"
            v-if="colData[0].istrue"
            :label="$t('list.Total_revenue')"
            align="center"
            width="80"
          >
            <template slot="header">
              <span class="vertical-middle">{{
                $t("list.Total_revenue")
              }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.personStatistics_content1')"
                placement="top-start"
              >
                <img src="./tip.png" alt="" class="imgSize" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="
                  $router.push({
                    name: 'payRecordQuery',
                    params: {
                      pdaManagerName: scope.row.pdaManagerName,
                      startTime: formInline.startTime,
                      endTime: formInline.endTime,
                    },
                  })
                "
              >
                <!-- Number((scope.row.money) / 100).toFixed(2) -->
                {{ scope.row.money ? scope.row.money + "元" : "0元" }}
              </el-button>
            </div>
          </el-table-column>

          <el-table-column
            prop="exitMoney"
            v-if="colData[1].istrue"
            :label="$t('list.PDA_on_site_fee_amount')"
            align="center"
            min-width="160"
          >
            <template slot="header">
              <span class="vertical-middle">{{
                $t("list.PDA_on_site_fee_amount")
              }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.personStatistics_content2')"
                placement="top-start"
              >
                <img src="./tip.png" alt="" class="imgSize" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="
                  $router.push({
                    name: 'payRecordQuery',
                    params: {
                      pdaManagerName: scope.row.pdaManagerName,
                      startTime: formInline.startTime,
                      endTime: formInline.endTime,
                    },
                  })
                "
              >
                <!-- Number((scope.row.money) / 100).toFixed(2) -->
                {{ scope.row.exitMoney ? scope.row.exitMoney + "元" : "0元" }}
              </el-button>
            </div>
          </el-table-column>

          <el-table-column
            prop="preMoney"
            v-if="colData[2].istrue"
            :label="$t('list.PDA_prepaid_amount')"
            align="center"
            min-width="130"
          >
            <template slot="header">
              <span class="vertical-middle">{{
                $t("list.PDA_prepaid_amount")
              }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.personStatistics_content3')"
                placement="top-start"
              >
                <img src="./tip.png" alt="" class="imgSize" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="
                  $router.push({
                    name: 'preOrderRecord',
                    params: {
                      pdaManagerName: scope.row.pdaManagerName,
                      startTime: formInline.startTime,
                      endTime: formInline.endTime,
                    },
                  })
                "
              >
                <!-- Number((scope.row.money) / 100).toFixed(2) -->
                {{
                  scope.row.preMoney || scope.row.preMoney == 0
                    ? scope.row.preMoney + "元"
                    : "0元"
                }}
              </el-button>
            </div>
          </el-table-column>

          <el-table-column
            prop="recoverMoney"
            :label="$t('list.PDA_overdue_collection_amount')"
            align="center"
            v-if="colData[3].istrue"
            min-width="160"
          >
            <template slot="header">
              <span class="vertical-middle">{{
                $t("list.PDA_overdue_collection_amount")
              }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.personStatistics_content4')"
                placement="top-start"
              >
                <img src="./tip.png" alt="" class="imgSize" />
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="
                  $router.push({
                    name: 'arrearsInquiries',
                    params: {
                      pdaManagerName: scope.row.pdaManagerName,
                      operationId: scope.row.operationId,
                      startTime: formInline.startTime,
                      endTime: formInline.endTime,
                    },
                  })
                "
                >{{
                  scope.row.recoverMoney ? scope.row.recoverMoney + "元" : "0元"
                }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="ticketMoney"
            :label="$t('list.Total_amount_of_small_ticket_payment')"
            align="center"
            v-if="colData[4].istrue"
            min-width="130"
          >
            <template slot="header">
              <span class="vertical-middle">{{
                $t("list.Total_amount_of_small_ticket_payment")
              }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.personStatistics_content5')"
                placement="top-start"
              >
                <img src="./tip.png" alt="" class="imgSize" />
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-button
                style="cursor: default; color: #606266"
                type="text"
                size="small"
                >{{
                  scope.row.ticketMoney ? scope.row.ticketMoney + "元" : "0元"
                }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="ticketDetailMoney"
            :label="$t('list.Small_ticket_on_site_payment_amount')"
            align="center"
            v-if="colData[5].istrue"
            min-width="150"
          >
            <template slot="header">
              <span class="vertical-middle">{{
                $t("list.Small_ticket_on_site_payment_amount")
              }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.personStatistics_content6')"
                placement="top-start"
              >
                <img src="./tip.png" alt="" class="imgSize" />
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-button
                style="cursor: default; color: #606266"
                type="text"
                size="small"
                >{{
                  scope.row.ticketDetailMoney
                    ? scope.row.ticketDetailMoney + "元"
                    : "0元"
                }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="ticketDeptMoney"
            :label="$t('list.amount_due_for_recovery_from_ticket_arrears')"
            align="center"
            v-if="colData[6].istrue"
            min-width="150"
          >
            <template slot="header">
              <span class="vertical-middle">{{
                $t("list.amount_due_for_recovery_from_ticket_arrears")
              }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.personStatistics_content7')"
                placement="top-start"
              >
                <img src="./tip.png" alt="" class="imgSize" />
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-button
                style="cursor: default; color: #606266"
                type="text"
                size="small"
                >{{
                  scope.row.ticketDeptMoney
                    ? scope.row.ticketDeptMoney + "元"
                    : "0元"
                }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="entryNums"
            :label="$t('list.Number_of_entry_operation_records')"
            align="center"
            width="130"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="
                  $router.push({
                    name: 'entryExitOperationPDA',
                    params: {
                      pdaManagerName: scope.row.pdaManagerName,
                      operationId: scope.row.operationId,
                      type: 1,
                      startTime: formInline.startTime,
                      endTime: formInline.endTime,
                    },
                  })
                "
                >{{
                  scope.row.entryNums ? scope.row.entryNums + "条" : "0条"
                }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="exitNums"
            :label="$t('list.Record_of_appearance_operations')"
            align="center"
            width="120"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="
                  $router.push({
                    name: 'entryExitOperationPDA',
                    params: {
                      pdaManagerName: scope.row.pdaManagerName,
                      operationId: scope.row.operationId,
                      type: 0,
                      startTime: formInline.startTime,
                      endTime: formInline.endTime,
                    },
                  })
                "
                >{{
                  scope.row.exitNums ? scope.row.exitNums + "条" : "0条"
                }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import timeRangePick from "@/components/timePicker";
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import screen from "@/components/screen/index";
//  import myComponent from '@/components/autocomplete/myautoComponent'

export default {
  name: "personStatistics",
  data() {
    let today = new Date(new Date().getTime());
    let yesterday = new Date(new Date().getTime());
    return {
      pdaManagerNameOptions: [], // 收费员下拉列表
      tenantList: [],
      timeType: "daterange",
      searchTime: [yesterday, today],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now();
        },
      },
      pageNum: 1,
      pageSize: 15,
      total: 10,
      // startTime: startTime,
      // endTime: endTime,
      parkList: [],
      tableData: [],
      colData: [
        { title: "营收总额", istrue: true },
        { title: "PDA现场收费金额", istrue: true },
        { title: "PDA预付金额", istrue: true },
        { title: "PDA欠费追缴金额", istrue: true },
        { title: "小票缴费总额", istrue: true },
        { title: "小票现场缴费金额", istrue: true },
        { title: "小票欠费缴费金额", istrue: true },
      ],
      // 多选框的列表，列出表格的每一列
      checkBoxGroup: [
        "营收总额",
        "PDA现场收费金额",
        "PDA预付金额",
        "PDA欠费追缴金额",
        "小票缴费总额",
        "小票现场缴费金额",
        "小票欠费缴费金额",
      ],
      // 当前选中的多选框，代表当前展示的列
      checkedColumns: [],
      tableCols1: [
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "account",
          label: this.$t("list.Login_Name"),
          width: "",
        },
        {
          prop: "pdaManagerName",
          label: this.$t("list.full_name"),
          width: "",
        },
      ],
      tableCols: [
        {
          prop: "operationName",
          label: this.$t("list.Total_revenue"),
          width: "",
        },
        {
          prop: "account",
          label: this.$t("list.PDA_on_site_fee_amount"),
          width: "",
        },
        {
          prop: "pdaManagerName",
          label: this.$t("list.PDA_prepaid_amount"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.PDA_overdue_collection_amount"),
          width: "",
        },
        {
          prop: "account",
          label: this.$t("list.Total_amount_of_small_ticket_payment"),
          width: "",
        },
        {
          prop: "pdaManagerName",
          label: this.$t("list.Small_ticket_on_site_payment_amount"),
          width: "",
        },
        {
          prop: "pdaManagerName",
          label: this.$t("list.amount_due_for_recovery_from_ticket_arrears"),
          width: "",
        },
      ],
      searchType: "historyData",
      loading: false,
      type1: "c3",
      type2: "c4",
      formInline: {
        account: "", // 登录名
        pdaManagerName: "",
        operationId: "",
        dateType: "1",
        startTime: "",
        endTime: "",
        pdaManagerState: "",
      },
    };
  },
  methods: {
    setReportFieldSave(checkedColumns) {
      this.isColCheckList = checkedColumns;
      let dataConlum = checkedColumns.join();
      this.$axios
        .post("/acb/2.0/reportFieldHide/save", {
          data: {
            reportType: 9,
            hideFields: dataConlum,
          },
        })
        .then((res) => {
          this.searchData();
        });
    },
    // 获取筛选项
    getReportFieldHide() {
      // 报表类型，1=临停报表；2=运营报表；3=停车卡报表；4=预约报表；5=营收报表；6=渠道统计；7=PDA统计
      this.$axios
        .get("/acb/2.0/reportFieldHide/get", {
          data: {
            reportType: "9",
          },
        })
        .then((res) => {
          if (res.value.length > 0) {
            this.checkedColumns = [];
            let tableName = this.checkList.filter((i) => {
              if (res.value.includes(i.prop)) {
                return i.prop;
              }
            });
            tableName.forEach((item) => {
              this.checkedColumns.push(item.label);
            });
          } else {
            this.checkedColumns = [];
            // this.checkedColumns = [
            //   "日期",
            //   "区域",
            //   "所属商户",
            //   "车场名称",
            //   "泊位数",
            //   "车场类型",
            //   "商用状态",
            //   "收入合计（元）",
            //   "优惠券金额（元）",
            //   "实付金额（元）",
            //   "单泊位收入（元）",
            //   "退款合计（元）",
            //   "退优惠（元）",
            //   "退实付（元）"
            // ];
          }
        });
    },
    setColData(colData) {
      this.colData = colData;
    },
    // 勾选筛选项
    setReportFieldHide(data) {
      let arr = this.checkList.filter((i) => {
        if (this.checkedColumns.includes(i.label)) {
          return i.prop;
        }
      });
      let hideFields = [];
      arr.forEach((item) => {
        hideFields.push(item.prop);
      });
      // 报表类型，1=临停报表；2=运营报表；3=停车卡报表；4=预约报表；5=营收报表；6=渠道统计；7=PDA统计
      this.$axios
        .post("/acb/2.0/reportFieldHide/save", {
          data: {
            reportType: "1",
            hideFields: hideFields.toString(),
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.getReportFieldHide();
          }
        });
    },
    // 列表点击事件
    screeningShow() {
      this.screeningNum = 1;
    },
    screeningHide() {
      this.screeningNum = 0;
    },
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    // 展开收起
    switchClick() {
      if (this.switchNum == 0) {
        this.switchNum = 1;
      } else {
        this.switchNum = 0;
      }
    },
    searchPdaManagerData() {
      let url = "/acb/2.0/pdaManager/query";
      this.$axios
        .get(url, {
          data: {
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.pdaManagerNameOptions = res.value.list;
          }
        });
    },
    timeChange(timeArr, shortcutKey) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
      if (shortcutKey) {
        this.searchData();
      }
    },
    // searchTimeChange() {
    //   let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";
    //   this.formInline.startTime = dateFormat(this.searchTime[0], tmp);
    //   this.formInline.endTime = dateFormat(this.searchTime[1], tmp);
    // },
    // 所属运营商选择
    getOperationInfo(val) {
      this.formInline.operationId = val;
    },
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    timeTypeChange() {
      if (this.formInline.dateType === "2") {
        this.timeType = "monthrange";
        let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";
        const startTime = new Date();
        const endTime = new Date();
        startTime.setTime(startTime.getTime());
        endTime.setTime(endTime.getTime());
        this.searchTime[0] = dateFormat(startTime, tmp);
        this.searchTime[1] = dateFormat(endTime, tmp);
        this.formInline.startTime = this.searchTime[0] + "00:00:00";
        this.formInline.endTime = this.searchTime[1] + "23:59:59";
      } else {
        this.timeType = "daterange";
        let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";
        const startTime = new Date();
        const endTime = new Date();
        startTime.setTime(startTime.getTime());
        endTime.setTime(endTime.getTime());
        this.searchTime[0] = dateFormat(startTime, tmp);
        this.searchTime[1] = dateFormat(endTime, tmp);
        this.formInline.startTime = this.searchTime[0] + "00:00:00";
        this.formInline.endTime = this.searchTime[1] + "23:59:59";
      }
    },
    // 清除
    clear() {
      this.timeType = "daterange";
      let today = new Date(new Date().getTime());
      let yesterday = new Date(new Date().getTime());
      this.searchTime = [yesterday, today];
      let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";

      this.formInline = {
        account: "",
        pdaManagerName: "",
        operationId: "",
        dateType: "1",
        startTime: dateFormat(this.searchTime[0], tmp) + "00:00:00",
        endTime: dateFormat(this.searchTime[1], tmp) + "23:59:59",
        pdaManagerState: "",
      };
      // 初始化日期
      this.$refs.timeRangePicker.resetTime();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 停车场名称下拉事件
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    // 时间判断
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time =
          new Date(this.formInline.endTime) -
          new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (!this.formInline.pdaManagerName && time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    showLog1() {
      if (this.formInline.startTime && this.formInline.endTime) {
        if (this.formInline.dateType === "1") {
          let startTime = dateFormat(
            new Date(this.formInline.startTime),
            "yyyy-MM-dd"
          );
          let endTime = dateFormat(
            new Date(this.formInline.endTime),
            "yyyy-MM-dd"
          );
          this.formInline.startTime = startTime;
          this.formInline.endTime = endTime;
          let data1 = Date.parse(startTime.replace(/-/g, "/"));
          let data2 = Date.parse(endTime.replace(/-/g, "/"));
          let datadiff = data2 - data1;
          let time = 30 * 24 * 60 * 60 * 1000;
          if (!this.formInline.pdaManagerName && datadiff > time) {
            this.$alert("搜索时间不能超过31天", "提示");
            return false;
          }
        } else if (this.formInline.dateType === "2") {
          let startTime = dateFormat(
            new Date(this.formInline.startTime),
            "yyyy-MM"
          );
          let endTime = dateFormat(
            new Date(this.formInline.endTime),
            "yyyy-MM"
          );
          this.formInline.startTime = startTime;
          this.formInline.endTime = endTime;
          var data1 = Date.parse(startTime.replace(/-/g, "/"));
          var data2 = Date.parse(endTime.replace(/-/g, "/"));
          var datadiff = data2 - data1;
          let time = 354 * 24 * 60 * 60 * 1000;
          if (datadiff > time) {
            this.$alert("搜索时间不能超过12个月", "提示");
            return false;
          }
        }
        if (this.formInline.startTime > this.formInline.endTime) {
          this.$alert("结束时间不能小于开始时间", "提示");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("开始/结束时间不能为空", "提示");
        return false;
      }
    },
    // 搜索
    searchData() {
      //        if (this.formInline.parkId != '') {
      //          this.$refs.parkInput.setShowVal(this.formInline.parkName)
      //          this.getReport();
      //        } else {
      //          this.$refs.parkInput.setShowVal('')
      //          this.getReport();
      //        }
      console.log(this.formInline, "this.formInline");
      //   let flag = this.showLog();
      //   if (flag) {
      this.$axios
        .get("/acb/2.0/pdaManager/getPdaManagerRecord", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            account: this.formInline.account,
            pdaManagerName: this.formInline.pdaManagerName,
            operationId: this.formInline.operationId,
            // dateType: this.formInline.dateType, // 必填，day/month
            startTime: this.formInline.startTime, // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
            endTime: this.formInline.endTime, // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
            pdaManagerState: this.formInline.pdaManagerState,
          },
        })
        .then((res) => {
          this.tableData = (res.value && res.value.list) || [];
          this.total = res.value.total * 1 || 0;
        });
      //   }
    },
    // 稽查员工作统计 导出
    exportExcelReport() {
      //   let flag = this.showLog();
      //   if (flag) {
      exportExcelNew("/acb/2.0/pdaManager/getPdaManagerRecordExport", {
        // dateType: this.formInline.dateType,
        pageNum: 0,
        pageSize: 0,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        operationId: this.formInline.operationId,
        pdaManagerName: this.formInline.pdaManagerName,
        account: this.formInline.account,
        pdaManagerState: this.formInline.pdaManagerState,
      });
      //   }
    },
  },
  components: {
    //      myComponent,
    timeRangePick,
    screen,
  },
  beforeDestroy() {},
  created() {
    // this.timeTypeChange();
    this.getTenantList();
    this.searchPdaManagerData();
  },
  computed: {
    defaultRangeValue() {
      return [
        {
          typeNumber: this.formInline.pdaManagerName,
          warntip: "请输入您查询的收费员",
        },
      ];
    },
  },
  watch: {
    checkedColumns(val) {
      let arr = this.checkBoxGroup.filter((i) => !val.includes(i));
      this.colData.filter((i) => {
        // if (arr.indexOf("渠道") != -1) {
        //   this.isShowPie = false;
        // } else {
        //   this.isShowPie = true;
        //   // this.searchData();
        // }
        if (arr.indexOf(i.title) != -1) {
          i.istrue = false;
        } else {
          i.istrue = true;
        }
      });
      this.isColCheckList = val;
      this.reload = Math.random();
      // this.setReportFieldSave();
    },
  },
  mounted() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.pagerWrapper {
  text-align: right;
  margin-top: 18px;
  font-size: 12px;
}

.content {
  overflow: hidden;

  .list {
    margin-top: 20px;

    .el-col {
      padding-bottom: 18px;
      height: 320px;

      .grid-content {
        padding-top: 15px;
        height: 100%;
      }

      h2 {
        font-size: 18px;
        text-align: center;
      }
    }
  }
}

.tableWrapper {
  position: relative;

  .vertical-middle {
    margin-right: 4px;
    vertical-align: middle;
  }

  img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }

  .switch {
    position: absolute;
    right: 2px;
    z-index: 2;
    top: 15px;
    cursor: pointer;

    img {
      height: 48px;
      width: 40px;
    }
  }
}

.imgSize {
  width: 14px;
  height: 14dpx;
}
</style>
